import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('./translations/en.json')
  }
})

const loadedLocales = ['en']

export async function setLocale(locale) {
  locale = locale || 'en'

  let success = true

  if (!loadedLocales.includes(locale)) {
    try {
      let localeFile = locale.replace('-','_')
      const messages = await import(
        /* webpackChunkName: "i18n-[request]" */ `./translations/${localeFile}.json`
      )
      i18n.setLocaleMessage(locale, messages)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Unable to load i18n locale ${locale}`)
      success = false
    }

    if (success) loadedLocales.push(locale)
  }

  i18n.locale = locale
}

export default i18n


