import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import './custom.scss'
import { kee } from './keecloak'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { i18n, setLocale } from "./i18n"
import store from "./store"

library.add(faGlobe)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = process.env.NODE_ENV != "production"

async function main() {
   await kee.init()
 
   if (kee.authenticated)
   {
     setLocale(kee.userInfo.locale)
   } else {
     setLocale(navigator.language.split('-')[0])
   }

   new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
 }
 
 main()