<template>
  <div>
    <eepos-header
      v-if="$route.name != 'registration' && user"
      :username="userName()"
      :locale="$i18n.locale"
      :testing="isTestingSystem"
      :token="token"
      @logout-click="logout"
    >
      <router-link to="/">User management</router-link>
      <div slot="subheader">
        <router-view name="header" />
      </div>
    </eepos-header>
    <router-view />
  </div>
</template>

<script>
import { kee } from "./keecloak";

export default {
  name: "app",
  data() {
    return {
      user: kee._initialized && kee.userInfo,
      token: null
    };
  },
  async created() {
    if (kee.authenticated) {
      this.$i18n.locale = kee.userInfo.locale;
    }
    this.token = kee.token
    kee.onTokenUpdate = (token) => {
      this.token = token
    }
  },
  methods: {
    logout() {
      kee.logout();
    },
    userName() {
      return kee.userInfo.name;
    }
  },
  computed: {
    isTestingSystem() {
      return process.env.VUE_APP_IS_TEST_SYSTEM == "true";
    },
  }
};
</script>
