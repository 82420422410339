import Vue from 'vue'
import VueRouter from 'vue-router'
import { kee } from '../keecloak'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/users',
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/registration",
  //   name: "registration",
  //   meta: { requiresAuth: false },

  //   components: {
  //     default: () => import(/* webpackChunkName: "registration" */ "../views/Registration.vue")
  //   },
  // },
  {
    path: '/subsidiaries',
    name: 'subsidiaries',
    meta: { requiresAuth: true, role: ['admin']  },

    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Subsidiary/List.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
  },
  {
    path: '/subsidiaries/new',
    name: 'subsidiaries_new',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Subsidiary/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: {
        itemId: null,
        mode: 'CREATE',
      },
    },
  },
  {
    path: '/subsidiaries/:id',
    name: 'subsidiaries_edit',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Subsidiary/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        itemId: route.params.id,
        mode: 'EDIT',
      }),
    },
  },
  {
    path: '/companies',
    name: 'companies',
    meta: { requiresAuth: true, role: ['subsidiary-admin', 'create-company']  },

    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Company/List.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        subsidiaryId: route.query.subsidiaryId,
        page : route.query.p ? parseInt(route.query.p) : 1,
        search: route.query.search,
        sortByQuery: route.query.sortBy,
        sortDescQuery: (route.query.sortDesc === 'true') || (route.query.sortDesc == true)
      }),
    },
  },
  {
    path: '/companies/new',
    name: 'companies_new',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Company/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        itemId: null,
        mode: 'CREATE',
        closeOnSave: Boolean(route.query.closeOnSave),
      }),
    },
  },
  {
    path: '/registration',
    name: 'registration',
    meta: { requiresAuth: false },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Registration.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    }
  },
  {
    path: '/companies/:id',
    name: 'companies_edit',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/Company/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        itemId: route.params.id,
        mode: 'EDIT',
      }),
    },
  },

  {
    path: '/users',
    name: 'users',
    meta: { requiresAuth: true, role: ['company-admin', 'create-company'] },

    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/User/List.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        companyId: route.query.companyId,
        page : route.query.p ? parseInt(route.query.p) : 1,
        search: route.query.search,
        sortByQuery: route.query.sortBy,
        sortDescQuery: (route.query.sortDesc === 'true') || (route.query.sortDesc == true)
      }),
    },
  },
  {
    path: '/users/new',
    name: 'users_new',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/User/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        type: route.params.resource_type,
        itemId: null,
        mode: 'CREATE',
      }),
    },
  },
  {
    path: '/users/:id',
    name: 'users_edit',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/User/Edit.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    },
    props: {
      default: (route) => ({
        type: route.params.resource_type,
        userId: route.params.id,
        mode: 'EDIT',
      })
    }
  },
  {
    path: '/me/language',
    name: 'languageselect',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/User/Language.vue'),
    }
  },
  {
    path: '/me',
    name: 'profile',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "edit" */ '../views/User/Profile.vue')
    }
  },
  {
    path: '/sync',
    name: 'sync',
    meta: { requiresAuth: true },
    components: {
      default: () =>
        import(/* webpackChunkName: "sync" */ '../views/Sync.vue'),
      header: () =>
        import(/* webpackChunkName: "header" */ '../views/DefaultHeader.vue'),
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!kee.authenticated) {
      kee.login()
    }

    if (to.meta.role != null && !to.meta.role.some( role => kee.userInfo?.roles["user-management"]?.includes(role))) {
      next({name: "profile"})
    }
    else {
      next()
    }

  } else {
    next()
  }
})

export default router
