import Keecloak from "keecloak"

export const kee = new Keecloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: "eepos",
    clientId: "user-management"
});

/**
 * @param {RequestInfo} input
 * @param {RequestInit} init
 */
export async function fetchAPI(input, init = {}) {
    const res = await fetch('/api' + input, {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${kee.token}`,
        ...init.headers,
      },
    })
    return res
  }
