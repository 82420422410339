import Vue from 'vue'
import Vuex from 'vuex'
import { kee } from '../keecloak'
import i18n from "../i18n";

Vue.use(Vuex)

/**
 * @param {RequestInfo} input
 * @param {RequestInit} init
 */
async function fetchAPI(input, init = {}) {
  const res = await fetch('/api' + input, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${kee.token}`,
      ...init.headers,
    },
  })
  return res
}

const store = new Vuex.Store({
  modules: {
    users: createStore('users'),
    companies: createStore('companies'),
    subsidiaries: createStore('subsidiaries'),
  },
})

export default store

function createStore(resourceName) {
  return {
    namespaced: true,
    state: {
      items: null,
      totalCount: null,
    },
    mutations: {
      setItems(state, { items, totalCount }) {
        state.items = items
        state.totalCount = totalCount
      },
    },
    actions: {
      async create(_, data) {
        const response = await fetchAPI(`/${resourceName}`, {
          method: 'POST',
          body: JSON.stringify({ ...data, id: undefined }),
        })

        if (!response.ok) throw Error(await extractErrorMessage(response))
        const body = await response.json()
        return body.id
      },
      async update(_, data) {
        const response = await fetchAPI(`/${resourceName}/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify({ ...data, id: undefined }),
        })

        if (!response.ok) throw Error(await extractErrorMessage(response))
      },
      async get(_, { queryParams = {}, id }) {
        const response = await fetchAPI(
          `/${resourceName}/${id}?${new URLSearchParams(queryParams)}`,
          {
            method: 'GET',
          }
        )
        if (!response.ok) throw Error(await extractErrorMessage(response))

        return response.json()
      },

      async fetchAll(context, params) {
        let searchParams = {}
        if (params != undefined) {
          if (params.sortBy != undefined)
            searchParams.sort_by = (params.sortDesc ? '-' : '') + params.sortBy
          if (params.query && params.query.trim().length > 0)
            searchParams.query = params.query
          if (params.limit != undefined && params.offset != undefined) {
            searchParams.limit = params.limit
            searchParams.offset = params.offset
          }
          if (params.filter != undefined) {
            searchParams = { ...searchParams, ...params.filter }
          }
        }

        const response = await fetchAPI(
          `/${resourceName}?${new URLSearchParams(searchParams)}`,
          {
            method: 'GET',
          }
        )
        if (response.ok) {
          const data = await response.json()
          if (params.updateState) {
            context.commit('setItems', data)
          }
          return data
        } else {
          throw Error(await extractErrorMessage(response))
        }
      },
      async delete(_, params) {
        const response = await fetchAPI(`/${resourceName}/${params.id}`, {
          method: 'DELETE',
        })
        if (!response.ok) throw Error(await extractErrorMessage(response))
        return response
      },
    },
  }
}

async function extractErrorMessage(res) {
  const text = await res.text()

  try {
    const json = JSON.parse(text)
    var jsonMessage = json.message

    if (json.code) {
      jsonMessage = i18n.t(`error.${json.code}`)
    }
    return jsonMessage || text
  } catch (e) {
    return text
  }
}
